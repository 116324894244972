import { RouteRecordRaw } from "vue-router";

const Menu: Array<RouteRecordRaw> = [
  {
    path: ":teamid/admin/user/index",
    name: "admin.user",
    component: () => import("@/product/paca/views/admin/user/IndexView.vue"),
  },
];

export default Menu;

import { RouteRecordRaw } from "vue-router";

let subs: Array<RouteRecordRaw> = [];

import HomeRouters from "@/product/paca/views/home/router";
subs = subs.concat(HomeRouters);

import AdminRouters from "@/product/paca/views/admin/router";
subs = subs.concat(AdminRouters);

const Menu: Array<RouteRecordRaw> = [
  {
    path: "/paca",
    name: "SysWebLoginss",
    component: () => import("@/product/paca/components/SidePanel.vue"),
    children: subs,
  },
];

export default Menu;

import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

import CONFIG from "@/config/config";

let routes: Array<RouteRecordRaw> = [
  
];

routes = routes.concat(CONFIG.ROUTERS);

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;

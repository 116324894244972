import { RouteRecordRaw } from "vue-router";

const Menu: Array<RouteRecordRaw> = [
  {
    path: "/web/",
    name: "NokiWeb",
    component: () => import("@/product/web/views/IndexView.vue"),
  }
];

export default Menu;

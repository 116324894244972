import { RouteRecordRaw } from "vue-router";

const Menu: Array<RouteRecordRaw> = [
  {
    path: ":teamid/home/noki/search",
    name: "search",
    component: () => import("@/product/paca/views/home/noki/SearchView.vue"),
  },
  {
    path: ":teamid/home/noki/edit/:nodeid?",
    name: "edit",
    component: () => import("@/product/paca/views/home/noki/EditView.vue"),
  },
];

export default Menu;

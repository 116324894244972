import { RouteRecordRaw } from "vue-router";

let Menu: Array<RouteRecordRaw> = [
];


import NokiRouters from "@/product/paca/views/admin/user/router";
Menu = Menu.concat(NokiRouters);


export default Menu;

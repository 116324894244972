import { RouteRecordRaw } from "vue-router";

const API = "wwww.pacasys.com";
let ROUTERS: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    redirect: "/web/",
  },
];
let LANGZH = {};
let LANGTW = {};
let LANGEN = {};
const LANGFR = {};
const LANGJA = {};
const LANGES = {};
const LANGRU = {};

// Sysweb 系统网站的宣传封面页面
import SyswebRouter from "@/product/sysweb/router";
ROUTERS = ROUTERS.concat(SyswebRouter);
import SyswebLang from "@/product/sysweb/lang";
LANGZH = { ...LANGZH, ...SyswebLang.langzh };
LANGTW = { ...LANGZH, ...SyswebLang.langtw };
LANGEN = { ...LANGZH, ...SyswebLang.langen };

// PACASYS 系统页面
import NokiRouter from "@/product/paca/router";
ROUTERS = ROUTERS.concat(NokiRouter);

// WEB 企业网站的页面
import WebRouter from "@/product/web/router";
ROUTERS = ROUTERS.concat(WebRouter);



export default {
  API,
  ROUTERS,
  LANGZH,
  LANGEN,
  LANGTW,
  LANGFR,
  LANGJA,
  LANGES,
  LANGRU,
};
import { RouteRecordRaw } from "vue-router";

const Menu: Array<RouteRecordRaw> = [
  {
    path: "/sysweb/",
    name: "SysWeb",
    component: () => import("@/product/sysweb/views/IndexView.vue"),
  },
  {
    path: "/sysweb/login/",
    name: "SysWebLogin",
    component: () => import("@/product/sysweb/views/LoginView.vue"),
  },
  {
    path: "/sysweb/forget/",
    name: "SysWebForget",
    component: () => import("@/product/sysweb/views/ForgetView.vue"),
  },
  {
    path: "/sysweb/reset/",
    name: "SysWebReset",
    component: () => import("@/product/sysweb/views/ResetView.vue"),
  },
  {
    path: "/sysweb/demo/",
    name: "SysWebDemo",
    component: () => import("@/product/sysweb/views/DemoIndex.vue"),
  },
];

export default Menu;
